<template>
  <div class="wordscard-container layout-border">
    <WordCards :wordCardList="wordCardList" @changeDisplay="handleChangeDisplay" 
    :lessonNum="11"/>
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: 'redColor',
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "xǐhuan",
          hanzi: "喜欢",
          english: "to like",
          image: require(`@/assets/img/12-Vocabulary/lesson-41-xihuan-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "chī",
          hanzi: "吃",
          english: "to eat",
          image: require(`@/assets/img/12-Vocabulary/lesson-41-chi-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "ma",
          hanzi: "吗",
          english: "(question particle)",
          image: require(`@/assets/img/12-Vocabulary/lesson-41-ma-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "four",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "píngguǒ",
          hanzi: "苹果",
          english: "apple",
          image: require(`@/assets/img/12-Vocabulary/lesson-41-pingguo-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "……吗？",
          hanzi: "... ma?",
          english: "(general question)",
          image: require(`@/assets/img/12-Vocabulary/lesson-41-card-ma.svg`),
          blue: true,
          longtext:true
        },
      ],
    };
  },
  methods:{
    handleChangeDisplay(index,isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg
    }
  }
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
