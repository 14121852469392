<template>
  <div class="singpage-container"  :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-41.mp3"),
      },
      timeStampList: [8.5, 12.5, 16.9, 21, 25.5, 29],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Nǐ xǐhuan chī shénme?",
            chineseWords: "你喜欢吃什么？",
          },
          {
            id: 2,
            spelling: "Wǒ xǐhuan chī píngguǒ.",
            chineseWords: "我喜欢吃苹果。",
          },
          {
            id: 3,
            spelling: "Nǐ xǐhuan chī píngguǒ ma?",
            chineseWords: "你喜欢吃苹果吗？",
          },
          {
            id: 4,
            spelling: "Wǒ xǐhuan! Wǒ xǐhuan!",
            chineseWords: "我喜欢！我喜欢！",
          },

          {
            id: 5,
            spelling: "Wǒ hěn xǐhuan chī píngguǒ.",
            chineseWords: "我很喜欢吃苹果。",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
