<template>
  <div class="game-container">
    <EatingFoodGame
      :optionList="optionList"
      :gameInfo="gameInfo"
      :answerIdArr="[1, 0, 1, 0, 0, 0, 1]"
    />
  </div>
</template>

<script>
import EatingFoodGame from "@/components/Course/GamePage/EatingFoodGame";
export default {
  data() {
    return {
      gameInfo: {
        bgImg: require("@/assets/img/16-GAMES/G36-dining-dino/lesson-41/background.svg"),
        defaultBgImg: require("@/assets/img/16-GAMES/G36-dining-dino/lesson-41/background-0.svg"),
        correctBgImg: require("@/assets/img/16-GAMES/G36-dining-dino/lesson-41/dino-1-chi.svg"),
        wrongBgImg: require("@/assets/img/16-GAMES/G36-dining-dino/lesson-41/dino-2-bu-chi.svg"),
        foodImg: require("@/assets/img/16-GAMES/G36-dining-dino/lesson-41/food.svg"),
      },

      optionList: [
        {
          id: 0,
          pinying: "chī",
          hanzi: "吃",
        },
        {
          id: 1,
          pinying: "bù chī",
          hanzi: "不吃",
        },
      ],
    };
  },
  components: {
    EatingFoodGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











