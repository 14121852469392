var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "eating-food-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "game-content" },
        [
          _c("Star", {
            attrs: {
              totalStarNumber: _vm.answerIdArr.length,
              currentIndex: _vm.starNo,
            },
          }),
          _vm.titleInfo
            ? _c("div", { staticClass: "title-area" }, [
                _c("div", { staticClass: "title" }, [
                  _c(
                    "div",
                    { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                    [_vm._v(" " + _vm._s(_vm.titleInfo.pinyin) + " ")]
                  ),
                  _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
                    _vm._v(" " + _vm._s(_vm.titleInfo.hanzi) + " "),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "background-img-area" }, [
            _c("img", {
              staticClass: "bg-img",
              attrs: { src: _vm.gameInfo.bgImg, alt: "" },
            }),
            _vm.isWaiting
              ? _c("img", {
                  staticClass: "bg-item-img",
                  attrs: { src: _vm.gameInfo.defaultBgImg },
                })
              : _vm._e(),
            !_vm.isWaiting
              ? _c("img", {
                  staticClass: "bg-item-img",
                  class: { isBehind: !this.answerIdArr[this.currentIndex] },
                  attrs: {
                    src: this.answerIdArr[this.currentIndex]
                      ? _vm.gameInfo.wrongBgImg
                      : _vm.gameInfo.correctBgImg,
                    alt: "",
                  },
                })
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "food-img-area",
                class: { isBehind: this.answerIdArr[this.currentIndex] },
              },
              [
                _c("img", {
                  class: "steps-" + _vm.steps,
                  attrs: { src: _vm.gameInfo.foodImg },
                }),
              ]
            ),
          ]),
          _c("div", { staticClass: "option-area" }, [
            _c("div", { staticClass: "redBackground" }),
            _c(
              "div",
              { staticClass: "bottonsWrapper" },
              _vm._l(_vm.optionList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "option-word bgcolor",
                    on: {
                      click: function ($event) {
                        return _vm.foundAnswer(item.id)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "font-pinyin-medium pinyin" }, [
                      _vm._v(_vm._s(item.pinying)),
                    ]),
                    _c("span", { staticClass: "font-hanzi-medium" }, [
                      _vm._v(_vm._s(item.hanzi)),
                    ]),
                    item.bottomImg
                      ? _c("img", { attrs: { src: item.bottomImg, alt: "" } })
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }