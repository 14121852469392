<template>
  <div class="game-container">
    <DragFruitsGame :gameList="gameList" :hasAudio="false" />
  </div>
</template>

<script>
import DragFruitsGame from "@/components/Course/GamePage/DragFruitsGame";
export default {
  data() {
    return {
      gameList: [
        {
          optionList: [
            {
              id: 1,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-41/1-apple.svg"),
              isCorrect: true,
            },
            {
              id: 2,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-41/1-watermelon.svg"),
            },
            {
              id: 3,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-41/1-banana.svg"),
            },
          ],
          hanzi: "苹果",
          pinyin: "píngguǒ",
          correctImg: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-41/1-apple-correct.svg"),
        },
        {
          optionList: [
            {
              id: 2,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-41/2-apple-da.svg"),
            },
            {
              id: 1,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-41/2-apple.svg"),
              isNotShow: true,
            },
            {
              id: 3,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-41/2-apple-xiao.svg"),
              isCorrect: true,
            },
          ],
          hanzi: "小苹果",
          pinyin: "xiǎo píngguǒ",
          correctImg: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-41/2-apple-xiao-correct.svg"),
        },
        {
          optionList: [
            {
              id: 1,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-41/3-apple-da.svg"),
              isCorrect: true,
            },
            {
              id: 3,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-41/3-apple.svg"),
              isNotShow: true,
            },
            {
              id: 2,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-41/3-apple-xiao.svg"),
            },
          ],
          hanzi: "大苹果",
          pinyin: "dà píngguǒ",
          correctImg: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-41/3-apple-da-correct.svg"),
        },
        {
          optionList: [
            {
              id: 1,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-41/4-apples-1-3.svg"),
              isCorrect: true,
            },
            {
              id: 2,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-41/4-apples-1-2.svg"),
            },
            {
              id: 3,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-41/4-apples-1-1.svg"),
            },
          ],
          hanzi: "一个大苹果和三个小苹果",
          pinyin: "yí ge dà píngguǒ hé sān ge xiǎo píngguǒ",
          correctImg: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-41/4-apples-correct.svg"),
        },
      ],
    };
  },
  components: {
    DragFruitsGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
