<template>
  <div class="eating-food-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content">
      <Star :totalStarNumber="answerIdArr.length" :currentIndex="starNo" />
      <div class="title-area" v-if="titleInfo">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ titleInfo.hanzi }}
          </div>
        </div>
      </div>
      <div class="background-img-area">
        <img :src="gameInfo.bgImg" alt="" class="bg-img" />
        <img
          class="bg-item-img"
          :src="gameInfo.defaultBgImg"
          v-if="isWaiting"
        />
        <img
          :src="
            this.answerIdArr[this.currentIndex]
              ? gameInfo.wrongBgImg
              : gameInfo.correctBgImg
          "
          alt=""
          class="bg-item-img"
          v-if="!isWaiting"
          :class="{isBehind: !this.answerIdArr[this.currentIndex]}"
        />
      <div class="food-img-area" :class="{isBehind: this.answerIdArr[this.currentIndex]}">
        <img :class="'steps-' + steps" :src="gameInfo.foodImg" />
      </div>
      </div>
      <div class="option-area">
        <div class="redBackground"></div>
        <div class="bottonsWrapper">
          <div
            v-for="(item, index) in optionList"
            :key="index"
            class="option-word bgcolor"
            @click="foundAnswer(item.id)"
          >
            <span class="font-pinyin-medium pinyin">{{ item.pinying }}</span>
            <span class="font-hanzi-medium">{{ item.hanzi }}</span>
            <img v-if="item.bottomImg" :src="item.bottomImg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import Star from "../Star";

export default {
  name: "EatingFoodGame",
  components: {
    PageButton,
    Star,
  },
  props: {
    optionList: {
      type: Array,
      require: true,
      default: () => [],
    },
    gameInfo: {
      type: Object,
      require: true,
      default: () => {},
    },
    titleInfo: {
      type: Object,
      require: false,
    },
    answerIdArr: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 1,
            endNumberRange: 100,
          },
        ],
      },
      isLastStep: false,

      currentIndex: 0,
      starNo: 0,
      socketInfo: {},
      steps: 0,
      isWaiting: true,
    };
  },
  mounted() {
    this.$bus.$on("eatingFoodClick", ({ id }) => {
      this.foundAnswer(id, true);
    });
    setTimeout(() => {
      this.steps++;
      setTimeout(() => {
        this.isWaiting = false;
      }, 2000);
    }, 2000);
  },
  beforeDestroy() {
    this.$bus.$off("eatingFoodClick");
  },
  watch: {
    socketInfo: {
      handler(value) {
        const data = {
          clickType: 20065,
          data: { value },
          text: "RecognitionPinyinGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    foundAnswer(id, isFromSocket = false) {
      if (this.starNo > this.answerIdArr.length) {
        return;
      }
      if (!isFromSocket) {
        this.socketInfo = {
          id,
          value: Math.random(),
        };
      }
      if (id === this.answerIdArr[this.currentIndex]) {
        this.steps++;
        this.isWaiting = true;
        playCorrectSound(true, false);
        setTimeout(() => {
          this.isWaiting = false;
          this.currentIndex++;
          this.starNo++;
          if (this.starNo === this.answerIdArr.length) {
            this.isLastStep = true;
            playCorrectSound();
            startConfetti();
          }
        }, 2000);
      } else {
        playCorrectSound(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.eating-food-game-container {
  .game-content {
    height: 100%;
    width: 100%;
    background: #f9f6e6;
    border-radius: 48px;
    position: relative;
    .title-area {
      z-index: 9;
      position: absolute;
      top: 0;
      left: 0;
      top: 8%;
      width: 20%;
      height: 17%;
      background: #cd4c3f;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      color: #fff;
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }
    }
    .background-img-area {
      width: 100%;
      height: 100%;
      z-index: 0;
      border-radius: 10%;
      position: relative;
      img {
        border-radius: 48px;
      }
      .bg-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 11;
      }
      .bg-item-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 11;
      }
    }
    .isBehind {
      z-index: 10 !important;
    }
    .food-img-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 82%;
      height: 100%;
      overflow: hidden;
      z-index: 11;
      mask-image: linear-gradient(to left, transparent, black 15%, black 40%, black 90%, transparent 100%);
      img {
        position: absolute;
        top: 0;
        right: -20%;
        height: 100%;
        transition: all 2s;
      }
      .steps-1 {
        right: -55%;
      }
      .steps-2 {
        right: -87%;
      }
      .steps-3 {
        right: -120%;
      }
      .steps-4 {
        right: -155%;
      }
      .steps-5 {
        right: -187%;
      }
      .steps-6 {
        right: -220%;
      }
      .steps-7 {
        right: -254%;
      }
      .steps-8 {
        right: -288%;
      }
    }
    .option-area {
      bottom: 0;
      width: 80%;
      height: 150px;
      position: absolute;
      display: flex;
      justify-content: center;
      margin-left: 11%;

      .redBackground {
        width: 50%;
        height: 50%;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        background-color: #d87065;
        position: absolute;
        bottom: 0;
      }
      .bottonsWrapper {
        display: flex;
        bottom: 0;
        height: -moz-fit-content;
        justify-content: space-around;
        height: 70%;
        padding: 20px;
        position: absolute;

        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        background-color: #ffffff;
        align-items: center;
        margin-bottom: 8px;
        .bgcolor {
          background-color: #cd4c3f;
          border-bottom: 8px solid #ac3d32;
          padding: 20px;
          box-sizing: border-box;
          max-width: 150px;
        }
        .bgcolor:hover {
          background-color: #912016;
          border-top: 8px solid #6e0c03;
        }
        .option-word {
          max-width: 150px;
          min-width: 100px;
          min-height: 100px;
          z-index: 1;
          border-radius: 15px;

          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          margin: 0 10px;
          cursor: pointer;
          span {
            color: #fff;
            font-size: 25px;
          }
          span:nth-child(2) {
            color: #fff;
            font-size: 30px;
          }
          img {
            max-width: 80%;
          }
        }
        .option-word:hover {
          min-width: 100px;
          min-height: 100px;
          border-radius: 15px;
          border-bottom: 0px;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          span {
            color: #fff;
            font-size: 25px;
          }
          span:nth-child(2) {
            color: #fff;
            font-size: 30px;
          }
        }
      }
    }
  }
}
</style>
