<template>
  <div class="game-container">
    <SpeakingSentence
      :titleInfo="titleInfo"
      :buttonList="buttonList"
      :gameList="gameList"
      @initGame="initGame"
      @showSentence="showSentence"
      @showSecondImg="showSecondImg"
      :isTwoImg="true"
      :lesson="411"
      :isBlueBackground="true"
    />
  </div>
</template>

<script>
import SpeakingSentence from "@/components/Course/CoursePage/SpeakingSentence";
export default {
  data() {
    return {
      originGameList: [],
      titleInfo: {
        hanzi: "（不）喜欢……",
        pinyin: "（ bù ） xǐhuan …",
      },
      gameList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-41-1/image-1-1.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-41-1/image-1-2.svg`),
          pinyin: "Tā xǐhuan yú.",
          hanzi: "她喜欢鱼。",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-41-1/image-2-1.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-41-1/image-2-2.svg`),
          pinyin: "Tā xǐhuan gǒu.",
          hanzi: "他喜欢狗。",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-41-1/image-3-1.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-41-1/image-3-2.svg`),
          pinyin: "Tā bù xǐhuan māo.",
          hanzi: "她不喜欢猫。",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-41-1/image-4-1.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-41-1/image-4-2.svg`),
          pinyin: "Tā bù xǐhuan cháng tóufa.",
          hanzi: "她不喜欢长头发。",
          isShowSentence: false,
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-41-1/menu-1.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-41-1/menu-2.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-41-1/menu-3.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-41-1/menu-4.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
      ],
    };
  },
  created() {
    this.originGameList = JSON.parse(JSON.stringify(this.gameList));
  },
  components: {
    SpeakingSentence,
  },
  methods: {
    initGame() {
      this.gameList.forEach((item, index) => {
        item.isShowSentence = false;
        item.bgImg = this.originGameList[index].bgImg;
      });
    },
    showSentence(index) {
      this.gameList[index - 1].isShowSentence = true;
    },
    showSecondImg(index) {
      this.gameList[index - 1].bgImg = this.gameList[index - 1].secondImg;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
