<template>
  <div class="wordscard-container layout-border">
    <WordImgCards
      :wordCardList="wordCardList"
      @changeTheImg="handleChangeDisplay"
      :lessonNum="41"
    />
  </div>
</template>
<script>
import WordImgCards from "@/components/Course/CoursePage/WordImgCards";

export default {
  components: {
    WordImgCards,
  },
  data() {
    return {
      wordCardList: [
        {
          pinyin: "Zhè shì jiějie ma?",
          hanzi: "这是姐姐吗?",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-41/card-3-jiejie.svg`),
          imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-41/card-3.svg`),
          Showing:true,
        },
        {
          pinyin: "Zhè shì dìdi ma?",
          hanzi: "这是弟弟吗?",
           image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-41/card-4-didi.svg`),
          imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-41/card-4.svg`),
          Showing:true,
        },
        {
          pinyin: "Zhè shì bàba ma?",
          hanzi: "这是爸爸吗?",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-41/card-1-baba.svg`),
          imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-41/card-1.svg`),
          Showing:true,
        },
        {
          pinyin: "Zhè shì māma ma?",
          hanzi: "这是妈妈吗?",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-41/card-2-mama.svg`),
          imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-41/card-2.svg`),
          Showing:true,
        },
      ],
    };
  },
  methods: {
    handleChangeDisplay(index) {
      this.wordCardList[index].Showing = !this.wordCardList[index].Showing;
    },
  },
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
